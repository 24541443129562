import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ErrorpageActions} from '../actions/errorpage.actions';
import {tap} from 'rxjs';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';


@Injectable()
export class ErrorpageEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private logger: NGXLogger,
  ) {
  }

  /**
   * Ist der Import-Benutzer-Service aufgrund eines Server Errors (HTTP 5xx) nicht verfügbar,
   * so zeigen wir die Service-Unavailable Errorpage an.
   */
  readonly importBenutzerServiceUnavailable$ = createEffect(
    () => this.actions$.pipe(
      ofType(ErrorpageActions.importBenutzerServiceUnavailable),
      tap(({
             error,
           }) => {
        this.logger.error(
          'show import-benutzer-service-unavailable error-page.',
          'status:',
          error.status,
          'url:',
          error.url);

        this.router.navigateByUrl('/error/service-unavailable');
      }),
    ), {dispatch: false}
  );
}
