import {routerReducer, RouterState} from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../../interfaces/app-state.interface';
import {benutzerReducer, initialBenutzerState} from './benutzer.reducer';
import {errorpageReducer, initialErrorpageState} from './errorpage.reducer';
import {benutzerFormReducer, initialBenutzerFormState} from './benutzer-form.reducer';


export const initialAppState: AppState = {
  router: RouterState.Minimal,
  benutzerReducer: initialBenutzerState,
  benutzerFormReducer: initialBenutzerFormState,
  errorpageReducer: initialErrorpageState,
};

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  benutzerReducer: benutzerReducer,
  benutzerFormReducer: benutzerFormReducer,
  errorpageReducer: errorpageReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
