import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';


export class ErrorpageActions {

  public static importBenutzerServiceUnavailable = createAction(
    '[Https Interceptor] Shows import-benutzer-service unavailable error-page.',
    props<{ error: HttpErrorResponse }>(),
  );
}
