import {BenutzerFormState} from '../../interfaces/benutzer-form-state.interface';
import {createReducer, on} from '@ngrx/store';
import {BenutzerFormActions} from '../actions/benutzer-form.actions';


export const initialBenutzerFormState: BenutzerFormState = {
  isModalOpen: false,
  personalFormValidation: false,
};

export const benutzerFormReducer = createReducer(
  initialBenutzerFormState,

  /*
   * INFO: Setzt Modal auf "offen" und speichert die übergebene BenutzerId.
   * Wird eine Benutzer-ID übergeben, ist davon auszugehen, dass ein Benutzer zum Bearbeiten
   * geöffnet wird. In dem Fall muss die Validierung initial korrekt sein.
   */
  on(
    BenutzerFormActions.openModal,
    (state, action) => ({
      ...state,
      isModalOpen: true,
      loadedBenutzerId: action.benutzerId,
      personalFormValidation: !!action.benutzerId,
    })
  ),

  // INFO: Setzt Modal auf "geschlossen" und löscht (falls vorhanden) die geladene BenutzerId.
  on(
    BenutzerFormActions.closedModal,
    (state) => ({
      ...initialBenutzerFormState,
    })
  ),

  // INFO: Aktualisiert personalFormData, sobald die Nutzer:in die Werte im Formular ändert.
  on(
    BenutzerFormActions.formValueChanges,
    (state, action) => ({
      ...state,
      personalFormData: action.personalFormData,
    })
  ),

  // INFO: Aktualisiert personalFormValidation, sobald die Nutzer:in die Werte im Formular ändert.
  on(
    BenutzerFormActions.formValidationChanges,
    (state, action) => ({
      ...state,
      personalFormValidation: action.personalFormValidation,
    })
  ),

  // INFO: Aktualisiert appsFormData, sobald die Nutzer:in die App-Auswahl ändert.
  on(
    BenutzerFormActions.updateAppSelection,
    (state, action) => ({
      ...state,
      appsFormData: action.appsFormData,
    })
  ),

);
