import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {from} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {PortalMessageBroker} from '../portal/portal-message-broker';


@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  // TODO:INIT: ggf. nötige Intents anlegen
  // JSON Schema  nicht vergessen 'src/assets/intents/<intent-id-with-underscore-and-underscore-version>.json'
  // private readonly berechtigungenDummyV1 = 'de.just-farming:berechtigungen:dummy';

  constructor(
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
    private router: Router,
  ) {
    if (this.isRunningInPortal()) {

      // TODO:INIT: ggf. nötige Intents konfigurieren
      /*
      this.portalMessageBroker.registerIntentCallback(this.berechtigungenDummyV1, '1', (data => {
        this.handleBerechtigungenDummyV1(data);
      }));
      */

      this.portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  // TODO:INIT: ggf. Senden/Empfangen Intent implementieren
  /*
  public documentReadIdV1(
    data: any
  ): void {
    if (this.isRunningInPortal()) {
      this.doBerechtigungenDummyV1(data);
    } else {
      this.handleBerechtigungenDummyV1(data);
    }
  }

  private handleBerechtigungenDummyV1(
    data: any
  ): void {
    // impl ...
  }

  private doBerechtigungenDummyV1(
    data: any
  ): void {
    this.doEmit(this.berechtigungenDummyV1, '1', data);
  }
  */

  /**
   * Prüft, ob die App im Portal läuft.
   */
  public isRunningInPortal(): boolean {
    return this.portalMessageBroker.isRunningInPortal();
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}

