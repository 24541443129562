import {Actions, createEffect, ofType} from '@ngrx/effects';
import {BenutzerPortalService} from '../../openapi/benutzer-openapi';
import {NGXLogger} from 'ngx-logger';
import {BenutzerActions} from '../actions/benutzer.actions';
import {concatMap, map, of, switchMap, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Injectable} from '@angular/core';
import {BenutzerFormActions} from '../actions/benutzer-form.actions';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BenutzerDeleteDialogComponent} from '../../modules/benutzer-delete-dialog/benutzer-delete-dialog.component';


@Injectable()
export class BenutzerEffects {

  private deleteDialogRef?: MatDialogRef<BenutzerDeleteDialogComponent>;

  constructor(
    private actions$: Actions,
    private benutzerService: BenutzerPortalService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }

  // INFO: Benutzer Laden Effects

  /**
   * Lädt alle Benutzer des Akteurs vom Service.
   */
  readonly readBenutzer$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.readBenutzer),
      switchMap(() => {

        return this.benutzerService.readBenutzer().pipe(
          map(benutzerDtos => {

            this.logger.debug(
              'read benutzer succeeded. benutzerIds:',
              benutzerDtos.map(benutzerDto => benutzerDto.id),
            );

            return BenutzerActions.readBenutzerSuccess({
              benutzerDtos,
            });
          }),
          catchError(error => of(error).pipe(
            map(() => {
              this.logger.error(
                'read benutzer failed. error: ',
                error,
              );

              return BenutzerActions.readBenutzerFailure({
                status: error.status,
              });
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden von Benutzern.
   */
  readonly readBenutzerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.readBenutzerFailure),
      map(({
             status,
           }) => {

        let errorMsg = '';

        switch (status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Laden der Benutzer. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 423: {
            errorMsg = 'Benutzer in ADNOVA+ gesperrt. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Benutzer. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  // INFO: Benutzer Erstellen Effects

  /**
   * Übermittelt die Daten zum Erstellen eines Benutzers an den Service.
   */
  readonly addBenutzer$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.addBenutzer),
      concatMap(({
                   updateDto
                 }) => {
        return this.benutzerService.addBenutzer(updateDto).pipe(
          map(responseDto => {

            this.logger.debug(
              'add benutzer succeeded. benutzerId:',
              responseDto.id,
            );

            return BenutzerActions.addBenutzerSuccess();
          }),
          catchError(error => of(error).pipe(
            map(() => {
              this.logger.error(
                'add benutzer failed. error:',
                error,
              );

              return BenutzerActions.addBenutzerFailure({
                status: error.status,
              });
            }),
          ))
        );
      }),
    )
  );

  /**
   * Success-Handling für das Erstellen eines Benutzers.
   */
  readonly addBenutzerSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.addBenutzerSuccess),
      concatMap(() => {
        this.snackbar.open(
          'Benutzer erstellt.',
          undefined,
          {
            duration: 5000,
          }
        );

        return [
          BenutzerFormActions.closeModal(),
          BenutzerActions.readBenutzer(),
        ];
      })
    )
  );

  /**
   * Error-Handling für das Erstellen von Benutzern.
   */
  readonly addBenutzerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.addBenutzerFailure),
      map(({
             status,
           }) => {

        let errorMsg = '';

        switch (status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Aktualisieren des Benutzers. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Pflichtangabe nicht gefüllt.';
            break;
          }
          case 409: {
            errorMsg = 'Der Benutzer existiert bereits.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Benutzers. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  // INFO: Benutzer Aktualisieren Effects

  /**
   * Übermittelt die Daten zum Aktualisieren eines Benutzers an den Service.
   */
  readonly updateBenutzer$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.updateBenutzer),
      concatMap(({
                   benutzerId,
                   updateDto,
                 }) => {

        return this.benutzerService.updateBenutzer(benutzerId, updateDto).pipe(
          map(() => {

            this.logger.debug(
              'update benutzer succeeded. benutzerId:',
              benutzerId,
            );

            return BenutzerActions.updateBenutzerSuccess();
          }),
          catchError(error => of(error).pipe(
            map(() => {
              this.logger.error(
                'update benutzer failed. benutzerId:',
                benutzerId,
                'error:',
                error,
              );

              return BenutzerActions.updateBenutzerFailure({
                status: error.status,
              });
            }),
          ))
        );
      }),
    )
  );

  /**
   * Success-Handling für das Aktualisieren eines Benutzers.
   */
  readonly updateBenutzerSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.updateBenutzerSuccess),
      concatMap(() => {
        this.snackbar.open(
          'Benutzer aktualisiert.',
          undefined,
          {
            duration: 5000,
          }
        );

        return [
          BenutzerFormActions.closeModal(),
          BenutzerActions.readBenutzer(),
        ];
      })
    )
  );

  /**
   * Error-Handling für das Aktualisieren von Benutzern.
   */
  readonly updateBenutzerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.updateBenutzerFailure),
      map(({
             status,
           }) => {

        let errorMsg = '';

        switch (status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Aktualisieren des Benutzers. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Pflichtangabe nicht gefüllt.';
            break;
          }
          case 423: {
            errorMsg = 'Benutzer in ADNOVA+ gesperrt. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Benutzers. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  // INFO: Benutzer Löschen Effects

  /**
   * Übermittelt die Daten zum Löschen eines Benutzers an den Service.
   */
  readonly deleteBenutzer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BenutzerActions.deleteBenutzer),
        concatMap(({
                     benutzerId,
                   }) => {
          return this.benutzerService.deleteBenutzer(benutzerId).pipe(
            map(() => {

              this.logger.debug(
                'delete benutzer succeeded. benutzerId:',
                benutzerId,
              );

              return BenutzerActions.deleteBenutzerSuccess();
            }),
            catchError(error => of(error).pipe(
              map(() => {
                this.logger.error(
                  'delete benutzer failed. benutzerId:',
                  benutzerId,
                  'error:',
                  error,
                );

                return BenutzerActions.deleteBenutzerFailure({
                  status: error.status,
                });
              }),
            ))
          );
        }),
      )
  );

  /**
   * Success-Handling für das Löschen eines Benutzers.
   */
  readonly deleteBenutzerSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.deleteBenutzerSuccess),
      concatMap(() => {
        this.snackbar.open(
          'Benutzer gelöscht.',
          undefined,
          {
            duration: 5000,
          }
        );

        return [
          BenutzerFormActions.closeModal(),
          BenutzerActions.closeDeleteBenutzerDialog(),
          BenutzerActions.readBenutzer(),
        ];

      })
    )
  );

  /**
   * Error-Handling für das Löschen von Benutzern.
   */
  readonly deleteBenutzerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.deleteBenutzerFailure),
      map(({
             status,
           }) => {

        let errorMsg = '';

        switch (status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Löschen des Benutzers. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 423: {
            errorMsg = 'Benutzer in ADNOVA+ gesperrt. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Benutzers. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });

        return BenutzerActions.closeDeleteBenutzerDialog();
      }),
    )
  );


// INFO: Benutzer Dialog Effects

  readonly openDeleteBenutzerDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.openDeleteBenutzerDialog),
      tap(
        ({benutzer}) => {
          const dialogConfig: MatDialogConfig = {
            data: {
              benutzerId: benutzer.id,
            },
          };
          this.deleteDialogRef = this.dialog.open(BenutzerDeleteDialogComponent, dialogConfig);
        }
      ),
    ), {dispatch: false}
  );

  readonly closeDeleteBenutzerDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerActions.closeDeleteBenutzerDialog),
      tap(() => {
        this.deleteDialogRef?.close(BenutzerDeleteDialogComponent);
      }),
    ), {dispatch: false}
  );
}
