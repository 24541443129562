import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IconService} from './modules/icon/icon.service';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {IntentActionService} from './services/intent.action.service';
import {AppState} from './interfaces/app-state.interface';
import {PreloadModulesService} from './services/preload-modules.services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  runningInPortal$: Observable<boolean>;

  title = 'berechtigungen';

  constructor(
    // INFO: initialisiere Icon-Service
    private iconService: IconService,
    private portalMessageBroker: PortalMessageBroker,
    private oidcSecurityService: OidcSecurityService,
    private store: Store<AppState>,
    private intentActionService: IntentActionService,
    private preloadModulesService: PreloadModulesService,
  ) {
    this.runningInPortal$ = this.portalMessageBroker.runningInPortal$
      .pipe(
        takeUntil(this.unsubscribe$),
      );
  }

  ngOnInit(): void {
    /*
     * INFO: In der folgenden Zeile wird die preloadModules Methode des PreloadModulesService aufgerufen,
     * um die vordefinierten Module vorzuladen.
     */
    this.preloadModulesService.preloadModules();
  }

  doLogout(): void {
    this.oidcSecurityService.logoff();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
