import {NgModule} from '@angular/core';
import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/auth.guard';
import {BenutzerResolver} from './resolver/benutzer.resolver';


const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  preloadingStrategy: PreloadAllModules,
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  },
  {
    path: 'overview',
    loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule),
    canActivate: [AuthGuard],
    resolve: [
      BenutzerResolver,
    ]
  },
  {
    path: 'impressum',
    loadChildren: () => import('./modules/impressum/impressum.module').then(m => m.ImpressumModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    canActivate: [AuthGuard],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
