import {createAction, props} from '@ngrx/store';
import {PersonalFormData} from '../../interfaces/personal-form-data.interface';
import {AppsFormData} from '../../interfaces/apps-form-data.interface';


export class BenutzerFormActions {

  /**
   * @action openModal
   * @description
   * Diese Aktion wird verwendet, um das Benutzerformular-Modal zu öffnen.
   * @property benutzerId Optional.
   * Wenn eine ID gegeben ist, wird ein bereits existierender Benutzer zum Bearbeiten geöffnet.
   * Ist die ID leer oder undefiniert, wird ein neuer Benutzer angelegt.
   */
  public static openModal = createAction(
    '[Benutzer Form] Open the form modal.',
    props<{
      benutzerId?: string,
    }>(),
  );

  /**
   * @action closeModal
   * @description
   * Diese Aktion wird verwendet, um das Benutzerformular-Modal zu schließen.
   */
  public static closeModal = createAction(
    '[Benutzer Form] Close the form modal.',
  );

  /**
   * @action closedModal
   * @description
   * Diese Aktion wird verwendet, um Änderungen am Store nach dem Schließen des Modals durchzuführen.
   */
  public static closedModal = createAction(
    '[Benutzer Form] The form modal was closed successfully.',
  );

  /**
   * @action formValueChanges
   * @description
   * Diese Aktion wird verwendet, um Veränderungen des Formulars im Store zu speichern.
   * @property personalFormData
   * Diese Eigenschaft enthält die aktuell im Formular eingegebenen Daten. Sie ist vom Typ PersonalFormData.
   */
  public static formValueChanges = createAction(
    '[Benutzer Form] Store the changed form values.',
    props<{
      personalFormData: PersonalFormData,
    }>(),
  );

  /**
   * @action formValidationChanges
   * @description
   * Diese Aktion wird verwendet, um Veränderungen des aktuellen Validierungsstatus im Store zu speichern.
   * @property personalFormValid
   * Diese Eigenschaft gibt den aktuellen Validierungsstatus des Formulars an. Wenn das Formular gültig ist, wird 'true'
   * übergeben, ansonsten 'false'.
   */
  public static formValidationChanges = createAction(
    '[Benutzer Form] Store the changed form validation result.',
    props<{
      personalFormValidation: boolean,
    }>(),
  );

  /**
   * @action updateAppSelection
   * @description
   * Diese Aktion wird verwendet, um die Auswahl von Apps im Store zu aktualisieren. Sie wird aufgerufen, wenn sich der
   * Auswahlstatus einer oder mehrerer Apps ändert.
   * @property appsFormData
   * Diese Eigenschaft beinhaltet den aktuellen Auswahlstatus aller Apps. Jede App ist entweder ausgewählt (true) oder
   * nicht ausgewählt (false).
   */
  public static updateAppSelection = createAction(
    '[Benutzer Form] Update the app selection.',
    props<{
      appsFormData: AppsFormData,
    }>(),
  );

}
