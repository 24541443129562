import {createAction, props} from '@ngrx/store';
import {BenutzerPortalUpdateDTO} from '../../openapi/benutzer-openapi';
import {BenutzerPortal} from '../../interfaces/benutzer-portal';


export class BenutzerActions {

  // INFO: Benutzer Laden Actions

  /**
   * @action readBenutzer
   * @description
   * Diese Aktion wird verwendet, um alle Benutzer zu laden.
   */
  public static readBenutzer = createAction(
    '[Overview] Read benutzer.',
  );

  public static readBenutzerSuccess = createAction(
    '[Benutzer Effect] Read benutzer successfully.',
    props<{
      benutzerDtos: BenutzerPortal[],
    }>()
  );

  public static readBenutzerFailure = createAction(
    '[Benutzer Effect] Read benutzer failed.',
    props<{
      status: number,
    }>()
  );

  // INFO: Benutzer Erstellen Actions

  /**
   * @action addBenutzer
   * @description
   * Diese Aktion wird verwendet, um einen neuen Benutzer zu erstellen.
   *
   * @prop benutzer Der zu erstellende Benutzer.
   */
  public static addBenutzer = createAction(
    '[Benutzer Form] Create benutzer.',
    props<{
      updateDto: BenutzerPortalUpdateDTO,
    }>(),
  );

  public static addBenutzerSuccess = createAction(
    '[Benutzer Effect] Created benutzer successfully.',
  );

  public static addBenutzerFailure = createAction(
    '[Benutzer Effect] Create benutzer failed.',
    props<{
      status: number,
    }>(),
  );

  // INFO: Benutzer Aktualisieren Actions

  /**
   * @action updateBenutzer
   * @description
   * Diese Aktion wird verwendet, um einen existierenden Benutzer zu aktualisieren.
   *
   * @prop benutzer Der zu aktualisierende Benutzer.
   */
  public static updateBenutzer = createAction(
    '[Edit Form Dialog] Update benutzer.',
    props<{
      benutzerId: string,
      updateDto: BenutzerPortalUpdateDTO,
    }>()
  );

  public static updateBenutzerSuccess = createAction(
    '[Benutzer Effect] Updated benutzer successfully.',
  );

  public static updateBenutzerFailure = createAction(
    '[Benutzer Effect] Update benutzer failed.',
    props<{
      status: number,
    }>()
  );


  // INFO: Benutzer Löschen Actions

  /**
   * @action deleteBenutzer
   * @description
   * Diese Aktion wird verwendet, um einen Benutzer zu löschen.
   *
   * @prop benutzer Der zu löschende Benutzer.
   */
  public static deleteBenutzer = createAction(
    '[Edit Form Dialog] Delete benutzer.',
    props<{
      benutzerId: string,
    }>()
  );

  public static deleteBenutzerSuccess = createAction(
    '[Benutzer Effect] Delete benutzer successfully.',
  );

  public static deleteBenutzerFailure = createAction(
    '[Benutzer Effect] Delete benutzer failed.',
    props<{
      status: number,
    }>(),
  );

  // INFO: Benutzer dialog popup Action

  public static openDeleteBenutzerDialog = createAction(
    '[Benutzer Effect] Open delete benutzer dialog.',
    props<{
      benutzer: BenutzerPortal,
    }>(),
  );

  public static closeDeleteBenutzerDialog = createAction(
    '[Benutzer Effect] Close delete benutzer dialog.',
  );

}
