import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {from, Observable, ReplaySubject} from 'rxjs';
import {v4 as uuid} from 'uuid';
import matchUrl from 'match-url-wildcard';
import {ContextInfo} from './context-info.service';


interface PendingResult {
  callId: string;
  accept: any;
  reject: any;
}

interface PortalMessage {
  type: string;
  functionName: string;
  functionData?: any;
  callId: string;
}

interface Intent {
  intentId: string;
  intentVersion: string;
  intentData: any;
}


@Injectable({
  providedIn: 'root'
})
export class PortalMessageBroker {

  /**
   * Prefix für alle Call-Ids<br>
   *
   * Über diesen Prefix und eine je Call zufällige Id wird sichergestellt<br>
   * das jeder Return-Werte sich wieder eindeutig dem Aufruf und somit dem CallBacks zuordnen lässt.<br>
   *
   * Durch das Prefix lassen sich Call-Ids eindeutig zu einer PortalMessageBroker Instanz zuordnen.
   *
   * @private
   */
  private readonly messagePrefix: string = 'broker-' + uuid();

  /**
   * Mapping zwischen Call-Ids und den Callbacks zum Aufruf
   *
   * @private
   */
  private readonly pendingResults: Map<string, PendingResult> = new Map();


  /**
   * Zwischengespeicherte Intents gespeichert nach Id und Version
   *
   * @private
   */
  private readonly cachedIntents: Map<string, Map<string, Intent[]>> = new Map();

  /**
   * Läuft die Anwendung im Portal
   *
   * @private
   */
  private readonly runningInPortal_ = new ReplaySubject<boolean>(1);

  /**
   * Sollen Intents zwischengespeichert werden?
   *
   * @private
   */
  private cacheIntents = true;

  /**
   * Mapping zwischen den Intent-Ids und den zugehörigen Callback-Funktionen
   *
   * @private
   */
  private readonly incomingIntentCallbacks: Map<string, Map<string, (data: any) => void>> = new Map();

  /**
   * Kennzeichen ob der PortalMessageBroker initialisiert ist
   *
   * @private
   */
  private inited = false;

  /**
   * MessagePort über den die Kommunikation mit dem Portal stattfindet
   *
   * @private
   */
  private messagePort?: MessagePort;

  /**
   * Current context infos
   *
   * @private
   */
  private currentContextInfo: ContextInfo = {
    properties: new Map(),
  };

  /**
   * Callbacks to receive context info changes
   *
   * @private
   */
  private readonly contextInfoCallbacks: Array<(contextInfo: ContextInfo) => void> = [];

  /**
   * Erstellt einen neuen PortalMessageBroker
   * <p>
   *   <b>Achtung</b>
   *   Vor der verwendet muss PortalMessageBroker.init aufgerufen werden.<br>
   * </p>
   *
   * @param logger
   */
  constructor(
    private logger: NGXLogger,
  ) {
    this.logger.debug('PortalMessageBroker: creating portal message broker: ', this.messagePrefix);
  }

  get runningInPortal$() {
    return this.runningInPortal_.asObservable();
  }

  /**
   * Initialisiert den PortalMessageBroker mit der Origin der Portal-Instanz
   * <p>
   *   Hierbei wird geprüft ob die Anwendung als IFrame läuft, <br>
   *   die Origin des Parents passt, <br>
   *   und es wird ein MessagePort geöffnet, wenn vorhanden. <br>
   * </p>
   *
   * @param targetOrigins Origin der Portal-Instanz
   */
  public init(targetOrigins: string): Promise<any> {
    this.logger.debug('Init message broker... ', targetOrigins);

    if (!this.inited) {
      const targetOriginList = targetOrigins.split(' ')
        .filter(origin => origin.startsWith('https://') || origin.startsWith('http://'));
      this.logger.debug('target origins... ', targetOrigins);

      return this.connect(targetOriginList)
        .then(value => {
          this.messagePort = value;
          let runningInPortal = false;

          if (this.messagePort) {
            this.messagePort.addEventListener('message', event => this.handleMessageEvent(event));
            /**
             * Sobald gestartet wird werden ggf. wartende Intents zugestellt.
             * Da zu diesem Zeitpunkt der entsprechende Callback noch nicht registriert ist,
             * werden diese Intents zwischengespeichert.
             *
             * Beim Registrieren eines Callbacks werden entsprechende Intents verarbeitet.
             *
             * {@link cachedIntents}
             * {@link cacheIntents}
             * {@link cacheIntent}
             * {@link registerIntentCallback}
             * {@link allIntentCallbackRegistered}
             */
            this.messagePort.start();

            runningInPortal = true;
          }
          this.inited = true;

          this.logger.debug('PortalMessageBroker.init(): message port ', this.messagePort);
          this.logger.info('PortalMessageBroker.init(): running in portal? ', this.isRunningInPortal());

          this.runningInPortal_.next(runningInPortal);

          return undefined;
        });
    }
    this.logger.warn('PortalMessageBroker.init(): already inited, ignoring... ', targetOrigins);

    return new Promise(resolve => resolve(undefined));
  }

  private getParentOrigin(): string | undefined {
    let parent: string | undefined = undefined;

    const ancestorOrigins = window.location.ancestorOrigins;
    this.logger.trace('window.location.ancestorOrigins: ', ancestorOrigins);
    if (ancestorOrigins && ancestorOrigins.length > 0) {
      parent = ancestorOrigins.item(0) ?? undefined;
    }
    // info: window.location.ancestorOrigins is not supported by every browser
    // fallback: document.referrer
    if (!parent) {
      const referrer = document.referrer;
      this.logger.trace('document.referrer: ', referrer);
      parent = referrer;
    }
    const parentUrl = this.parseUrl(parent);
    this.logger.trace('parent url: ', parentUrl);
    return parentUrl?.origin;
  }

  private parseUrl(
    urlString: string,
  ): URL | undefined {
    if (urlString) {
      try {
        const url = new URL(urlString);
        if (url.protocol === 'http:' || url.protocol === 'https:') {
          return url;
        }
      } catch (error) {
      }
    }
    return undefined;
  }

  private connect(targetOrigins: string[]): Promise<MessagePort | undefined> {
    const parentOrigin = this.getParentOrigin();
    this.logger.debug('parent origin: ', parentOrigin);

    const matchingTargets = targetOrigins.filter(value => {
      if (value === parentOrigin) {
        return true;
      }
      if (parentOrigin && matchUrl(parentOrigin, value)) {
        return true;
      }
      return false;
    });
    this.logger.debug('matching targets: ', matchingTargets);

    let targetOrigin: string | undefined = undefined;
    if (matchingTargets.length > 0) {
      targetOrigin = parentOrigin;
    }

    return new Promise<boolean>((resolve, reject) => {
      if (this.checkParentOrigin(targetOrigin)) {
        resolve(true);
      }
      resolve(false);
    }).then(value => {
      if (value) {
        return this.createMessagePort(targetOrigin);
      }
      return undefined;
    });
  }

  private parseData(event: MessageEvent<any>): any {
    if (event.data) {
      try {
        return JSON.parse(event.data);
      } catch (e) {
        this.logger.warn('PortalMessageBroker: could not parse message event data: ', event, e);
      }
    }
    return undefined;
  }

  private handleMessageEvent(event: MessageEvent<any>): void {
    this.logger.debug('PortalMessageBroker.handleMessageEvent(): received MessageEvent: ', event);

    const data = this.parseData(event);
    if (!data) {
      // consider data is present
      this.logger.debug('PortalMessageBroker.handleMessageEvent(): no data present');
      return;
    }
    if (data.type === 'callResult') {
      this.handleCallResult(data);
    } else if (data.type === 'dispatchIntent') {
      this.handleDispatchIntent(data);
    } else if (data.type === 'context-info') {
      this.handleDispatchContextInfo(data);
    } else {
      this.logger.debug('PortalMessageBroker.handleMessageEvent(): unknown data type: ', data.type);
    }
  }

  private handleCallResult(
    data: any
  ): void {

    if (!data.callId) {
      // INFO: consider callId is present
      this.logger.debug('PortalMessageBroker.handleCallResult(): unknown data call id: ', data.callId);
      return;
    }
    const callId = data.callId;

    const pendingResult = this.pendingResults.get(callId);
    if (!pendingResult) {
      // INFO: consider callback present
      this.logger.debug('PortalMessageBroker.handleCallResult(): no pending result found for call id: ', data.callId);
      return;
    }

    this.logger.debug('PortalMessageBroker.handleCallResult(): handling data result: ', data.result);
    const promiseResultFunction = data.success ? pendingResult.accept : pendingResult.reject;
    promiseResultFunction(data.result);

    // INFO: cleanup callbacks
    this.pendingResults.delete(callId);
  }

  private handleDispatchIntent(
    data: any
  ): void {

    const intentId = data.intentId;
    if (!intentId) {
      this.logger.debug('PortalMessageBroker.handleDispatchIntent(): ignoring intent, intentId missing', data);
      return;
    }
    const intentVersion = data.intentVersion;
    if (!intentVersion) {
      this.logger.debug('PortalMessageBroker.handleDispatchIntent(): ignoring intent, intentVersion missing', data);
      return;
    }
    this.handleDispatchIntentInternal(data);
  }

  private handleDispatchIntentInternal(
    intent: Intent,
  ): void {
    const versionCallbacks = this.incomingIntentCallbacks.get(intent.intentId);
    if (!versionCallbacks) {
      if (this.cacheIntents) {
        this.logger.debug('PortalMessageBroker.handleDispatchIntentInternal(): caching intent, no callback for intentId', intent);
        this.cacheIntent(intent);
      } else {
        this.logger.debug('PortalMessageBroker.handleDispatchIntentInternal(): ignoring intent, no callback for intentId', intent);
      }
      return;
    }
    const callback = versionCallbacks.get(intent.intentVersion);
    if (!callback) {
      if (this.cacheIntents) {
        this.logger.debug('PortalMessageBroker.handleDispatchIntentInternal(): caching intent, no callback for intentVersion', intent);
        this.cacheIntent(intent);
      } else {
        this.logger.debug('PortalMessageBroker.handleDispatchIntentInternal(): ignoring intent, no callback for intentVersion', intent);
      }
      return;
    }
    this.logger.debug('PortalMessageBroker.handleDispatchIntentInternal(): execute callback', callback.name, intent.intentData);
    callback(intent.intentData);
  }

  private cacheIntent(
    intent: Intent,
  ): void {
    this.logger.debug('PortalMessageBroker.cacheIntent(): cahc intent', intent);

    let idMap = this.cachedIntents.get(intent.intentId);
    if (!idMap) {
      idMap = new Map();
      this.cachedIntents.set(intent.intentId, idMap);
    }
    let versionIntents = idMap.get(intent.intentVersion);
    if (!versionIntents) {
      versionIntents = [];
      idMap.set(intent.intentVersion, versionIntents);
    }
    versionIntents.push(intent);
  }

  private handleDispatchContextInfo(
    message: any
  ): void {
    const data = message.data;
    if (!data) {
      this.logger.debug('PortalMessageBroker.handleDispatchContextInfo(): ignoring context info message, data missing', message);
      return;
    }
    const properties = data.properties;
    if (!properties) {
      this.logger.debug('PortalMessageBroker.handleDispatchContextInfo(): ignoring context info message, data.properties missing', message);
      return;
    }

    // info: maps are not serialized, result would be an empty object
    // therefor 'Object.fromEntries(<map>)' is used on shell side
    // this side has to use 'new Map(Object.entries(<object>))'
    const contextInfo: ContextInfo = {
      properties: new Map(Object.entries(properties)),
    };
    this.logger.debug('PortalMessageBroker.handleDispatchContextInfo(): context info', contextInfo);

    // save and deliver new context info
    this.currentContextInfo = contextInfo;
    this.contextInfoCallbacks.forEach(callback => {
      callback(contextInfo);
    });
  }

  /**
   * Register callback for context info changes
   *
   * @param callback Callback for new context infos
   */
  public registerContextInfoCallback(
    callback: (contextInfo: ContextInfo) => void,
  ): void {
    this.contextInfoCallbacks.push(callback);
    // deliver current context info
    callback(this.currentContextInfo);
  }

  private calcCallId(): string {
    return this.messagePrefix + '_call-' + uuid();
  }

  private callFunction(name: string, parameter?: any, timeout = 90000): Promise<any> {
    if (!this.isRunningInPortal()) {
      // INFO: consider running in portal
      throw new Error('Application is not running in portal');
    }

    this.logger.trace('PortalMessageBroker.callFunction(): dispatching function call ', name, parameter, timeout);
    const callId = this.calcCallId();

    const portalMessage: PortalMessage = {
      type: 'callFunction',
      functionName: name,
      functionData: parameter,
      callId,
    };

    const promise = new Promise((accept, reject) => {
      const pendingResult = {
        callId,
        accept,
        reject,
      };
      this.pendingResults.set(callId, pendingResult);
      this.logger.trace('PortalMessageBroker.callFunction(): registered pending result: ', pendingResult);
    });
    (this.messagePort as MessagePort).postMessage(JSON.stringify(portalMessage));
    this.logger.trace('PortalMessageBroker.callFunction(): message sent: ', portalMessage);

    // INFO abort pending promises after timout
    window.setTimeout(() => {
      const pendingResult = this.pendingResults.get(callId);
      if (pendingResult) {
        this.logger.debug('PortalMessageBroker.callFunction(): timout for pending result reached:', callId, timeout);
        pendingResult.reject('Timout');
      }
      this.pendingResults.delete(callId);
    }, timeout);

    return promise;
  }

  private checkInit(): void {
    if (!this.inited) {
      throw new Error('PortalMessageBroker is not initialized, call inti()');
    }
  }

  private checkRunningInPortal(): void {
    if (!this.isRunningInPortal()) {
      throw new Error('application is not running in portal');
    }
  }

  /**
   * Prüft ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    this.checkInit();

    if (this.messagePort) {
      return true;
    }
    return false;
  }

  /**
   * Gibt das ADNOVA+ JWT zurück, wenn möglich
   */
  public requestAdnovaToken(): Observable<string> {
    this.checkRunningInPortal();

    this.logger.trace('PortalMessageBroker.requestAdnovaToken(): request new token');

    const promise = this.callFunction('getAuthToken', undefined, 2000);
    return from(promise);
  }

  private async createMessagePort(targetOrigin?: string): Promise<MessagePort> {
    const localLogger = this.logger;
    let windowsListener: EventListener;

    const localMessagePortPromise = new Promise<MessagePort>((resolve, reject) => {
      const localListener = function listen(event: MessageEvent): void {
        localLogger.trace('PortalMessageBroker.createMessagePort(): received MessageEvent: ', event);

        const matchingOrigins = targetOrigin && event.origin.startsWith(targetOrigin);
        if (!matchingOrigins) {
          // INFO:  ignore messages from other origins
          localLogger.debug('PortalMessageBroker.createMessagePort(): event origin does not match target origin: ', event.origin);
          return;
        }
        if (event.data !== 'set-message-channel') {
          // INFO: consider only defines messages
          localLogger.debug('PortalMessageBroker.createMessagePort(): unknown data received: ', event.data);
          return;
        }
        if (event.ports.length < 1) {
          // INFO: consider only events with ports
          localLogger.debug('PortalMessageBroker.createMessagePort(): event without message ports received');
          return;
        }

        const localMessagePort = event.ports[0];
        if (!(localMessagePort instanceof MessagePort)) {
          // INFO: consider only message ports
          localLogger.debug('PortalMessageBroker.createMessagePort(): received message port is no MessagePort: ', localMessagePort);
          return;
        }

        localLogger.debug('PortalMessageBroker.createMessagePort(): received message port: ', localMessagePort);
        resolve(localMessagePort);

        // INFO: remove Listener if successful
        window.removeEventListener('message', localListener);
      };

      windowsListener = localListener as EventListener;
      window.addEventListener('message', windowsListener, false);

      window.setTimeout(() => {
        this.logger.trace('PortalMessageBroker.createMessagePort(): timout for window message listener reached, removing... ',
          windowsListener);

        window.removeEventListener('message', windowsListener);

        reject('timout reached');
      }, 2000); // TODO: Timeout klären oder variabel gestalten
    });

    if (targetOrigin) {
      window.parent.postMessage('request-message-channel', targetOrigin);
      this.logger.debug('PortalMessageBroker.createMessagePort(): message channel request sent: ', targetOrigin);
    }

    return localMessagePortPromise;
  }

  private checkParentOrigin(targetOrigin?: string): boolean {
    if (!this.isRunningInIFrame()) {
      this.logger.debug('PortalMessageBroker.checkParentOrigin(): is not running in iframe');
      return false;
    }
    if (window.location === window.parent.location) {
      this.logger.debug('PortalMessageBrokercheckParentOrigin(): iframe and parent location are the same');
      return false;
    }
    if (!targetOrigin) {
      this.logger.debug('PortalMessageBroker.checkParentOrigin(): target origin not present: ', targetOrigin);
      return false;
    }
    this.logger.debug('PortalMessageBroker.checkParentOrigin(): parent origin is ok', targetOrigin);
    return true;
  }

  private isRunningInIFrame(): boolean {
    try {
      return window.top !== window.self;
    } catch (e) {
      this.logger.debug('PortalMessageBroker.isRunningInIFrame(): could not check for iframe', e);
      return false;
    }
  }

  /**
   * Registriert eine Callback-Funktion für einen Intent
   *
   * Ob der Intent durch das Portal auch zur Anwendung gerouted wird, ist eine App-Einstellung
   *
   * @param intentId Id des Intent
   * @param intentVersion Version des Intent die unterstützt wird
   * @param callback Callback der bei einem eingehenden Intent aufgerufen wird
   */
  public registerIntentCallback(
    intentId: string,
    intentVersion: string,
    callback: (data: any) => void,
  ): void {
    this.checkRunningInPortal();

    // some simple validations
    if (intentId.length < 1) {
      throw new Error('intentId can not be empty');
    }
    if (intentVersion.length < 1) {
      throw new Error('intentVersion can not be empty');
    }

    this.logger.debug('PortalMessageBroker.registerIntentCallback(): register intent callback', intentId, intentVersion, callback);

    let versionCallbacks = this.incomingIntentCallbacks.get(intentId);
    if (!versionCallbacks) {
      versionCallbacks = new Map();
      this.incomingIntentCallbacks.set(intentId, versionCallbacks);
    }
    const versionCallback = versionCallbacks.get(intentVersion);
    if (versionCallback) {
      throw new Error('there is already a callback for ' + intentId + ' with version ' + intentVersion);
    }
    versionCallbacks.set(intentVersion, callback);

    const idMap = this.cachedIntents.get(intentId);
    if (idMap) {
      const intents = idMap.get(intentVersion);
      if (intents && intents.length > 0) {
        this.logger.debug('PortalMessageBroker.registerIntentCallback(): handle cached intents', intentId, intentVersion, callback);

        intents.forEach(intent => {
          this.logger.trace('PortalMessageBroker.registerIntentCallback(): handle cached intent', intent);
          this.handleDispatchIntentInternal(intent);
        });
        intents.length = 0; // clear intents
      }
    }
  }

  /**
   * Signalisiert das alle Intent-Callbacks registriert wurde.
   *
   * Hierdurch werden noch gecachte Intents verworfen
   * und keine weiteren Intents gecacht.
   *
   */
  public allIntentCallbackRegistered(): void {
    this.checkRunningInPortal();

    this.cacheIntents = false;
    this.logger.debug('PortalMessageBroker.allIntentCallbackRegistered(): disabled intent caching');

    this.cachedIntents.forEach(versionIntents =>
      versionIntents.forEach(intents => {
        if (intents && intents.length) {
          this.logger.warn('PortalMessageBroker.allIntentCallbackRegistered(): discarding disregarded intents', intents);
        }
      })
    );
    this.cachedIntents.clear();
  }

  /**
   * Sendet ein Intent an das Portal
   *
   * @param intentId Id des Intent
   * @param intentVersion Version des Intent
   * @param intentData Daten des Intent, welche Daten ein Intent fordert ist der jeweiligen Dokumention zu entnehmen
   *
   * @return Gibt bei erfolgreichem Aufruf die App-Id der Anwendung zurück, die den Intent behandelt hat
   */
  public emitIntent(
    intentId: string,
    intentVersion: string,
    intentData: any,
  ): Promise<any> {
    this.checkRunningInPortal();

    // some simple validations
    if (intentId.length < 1) {
      throw new Error('intentId can not be empty');
    }
    if (intentVersion.length < 1) {
      throw new Error('acceptedIntentVersions are missing');
    }

    const intent: Intent = {
      intentId,
      intentVersion,
      intentData,
    };

    this.logger.debug('PortalMessageBroker.emitIntent(): emitting intent', intent);

    return this.callFunction('emitIntent', intent)
      .then(result => {
        // fail
        if (result.result !== 'SUCCESS') {
          this.logger.debug('PortalMessageBroker.emitIntent(): failed to dispatch intent', intent, result);
          return new Promise((accept, reject) => {
            reject(result.message);
          });
        }
        // success
        this.logger.debug('PortalMessageBroker.emitIntent(): succeeded to dispatch message', intent, result);
        return result.targetAppId;
      });
  }

  /**
   * Sendet eine Anfrage zum Schließen eines Intent-Popups
   *
   * @return Gibt bei erfolgreichem Aufruf die App-Id der Anwendung zurück, die den Intent behandelt hat
   */
  public closeIntent(): Promise<any> {
    this.checkRunningInPortal();

    this.logger.debug('PortalMessageBroker.closeIntent(): close popup');

    return this.callFunction('requestClose', null)
      .then(result => {
        // fail
        if (result.result !== 'SUCCESS') {
          this.logger.debug('PortalMessageBroker.closeIntent(): failed to close popup', result);
          return new Promise((accept, reject) => {
            reject(result.message);
          });
        }
        // success
        this.logger.debug('PortalMessageBroker.closeIntent(): succeeded to close popup', result);
        return result.targetAppId;
      });
  }

  /**
   * Wenn die Application als PopUp gestartet wurde,
   * kann über diese Funktion signalisiert werden das PopUp wieder zu schließen
   *
   * Dies funktioniert nur wenn die Anwendung auch als PopUp geöffnet wurde
   *
   * @return Gibt bei erfolgreichem Ausführen 'OK' zurück
   */
  public requestPopupClose(): Promise<any> {
    this.checkRunningInPortal();

    this.logger.debug('PortalMessageBroker.requestPopupClose(): requesting popup close');

    return this.callFunction('requestClose', undefined, 10_000);
  }
}
