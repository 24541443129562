<h2 mat-dialog-title>
  <div class="top-right-container">
    <b>Nutzer löschen?</b>
    <button
      mat-icon-button
      class="close-button"
      (click)="exitDialog()"
      [disabled]="loading"
    >
      <app-icon icon="close" primaryStroke="#666"></app-icon>
    </button>
  </div>
</h2>
<mat-dialog-content>
  <p class="dialog-delete-confirmation">
    Diese Aktion lässt sich nicht rückgängig <br>
    machen, die Daten sind für immer <br>
    verloren.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button
          color="accent"
          (click)="deleteUser()"
          [autofocus]="false"
          [disabled]="loading"
          class="dialog-delete-confirmation-button"
  >
    <span *ngIf="!loading; else deletingText">Endgültig löschen</span>
  </button>
  <ng-template #deletingText>
    <span>Wird gelöscht </span>
    <mat-progress-spinner
      color="accent"
      diameter="18"
      mode="indeterminate"
      class="loading-wheel visible"
      *ngIf="loading"
    ></mat-progress-spinner>
  </ng-template>
  <button
    mat-stroked-button
    (click)="exitDialog()"
    [autofocus]="false"
    [disabled]="loading"
    color="{{!loading ? 'accent': 'gray'}}"
  >
    Abbrechen
  </button>
</mat-dialog-actions>
