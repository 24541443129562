import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


interface IconSpec {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class IconService {

  iconList: IconSpec[] = [
    {
      name: 'delete',
    },
    {
      name: 'arrow_left',
    },
    {
      name: 'neuen_nutzer_hinzufuegen',
    },
    {
      name: 'board',
    },
    {
      name: 'pen',
    },
    {
      name: 'close',
    },
    {
      name: 'empty_state'
    },
    // TODO:INIT: nötige Icons einfügen
    /*
    {
      name: 'name-without-dot-svg',
    },
    */
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.name}.svg`)
      );
    });
  }
}
